.hoursContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 6px;
  column-gap: 6px;
}

.hour {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f3ec;
  border: 1px solid #f6f3ec;
  border-width: 1px;
  border-style: solid;
  height: 50px;
  font-size: 15px;
  border-radius: 8px;
  flex: 0 0 32%;
  text-align: center;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  transition: all 0.3s ease-in-out;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
}

.hourSelected {
  background-color: white;
  border: 2px solid #a5d663;
  box-shadow: 0px 3px 20px 6px rgba(50, 50, 71, 0.1);
  color: black;
}

.partnerHourSelected {
  background-color: white;
  border: 2px solid #00c2a3;
  box-shadow: 0px 3px 20px 6px rgba(50, 50, 71, 0.1);
  color: black;
}

.hourDisabled {
  background-color: #cbcbcb;
  color: white;
  border-color: #cbcbcb;
  pointer-events: none;
}

.notAvailableHoursMessage {
  text-align: center;
}

.notAvailableHoursMessage span {
  font-size: 24px;
  color: gray;
}

.notAvailableHoursMessage p {
  color: gray;
}
