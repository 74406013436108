.watermark {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
}

.watermark span {
  font-weight: 600;
  margin-right: 16px;
}

.watermarkLogo {
  width: 100px;
}

.watermarkSwitchContainer {
  display: flex;
  align-items: center;
  margin-top: 32px;
}

.watermarkSwitchText {
  margin-right: 16px;
  font-weight: 600;
}

.watermarkDisabledInfo {
  margin-left: 20px;
}
