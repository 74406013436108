.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-color: #847272;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: black;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: black;
}

.ant-radio-button-wrapper {
  border-color: #EBEBEB;
}

.ant-radio-button-wrapper:hover {
  color: black;
}

.ant-progress-success-bg, .ant-progress-bg {
  background-color: #A5D663;
}

.ant-input {
  background-color: #FFFFFF;
  border-color: 1px solid #CBD5E0;
  height: 40px;
  padding: 10px 16px;
}

.ant-input:not(:placeholder-shown) {
  background-color: #FFFFFF;
  border-color: #CBD5E0;
}

.ant-input:focus:not(:placeholder-shown) {
  background-color: white;
  border-color: #CBD5E0;
}

.ant-input:focus {
  box-shadow: 0 0 0 2px #A5D663;
  background: white;
  background-color: white;
}

.sequra-input .ant-input:focus {
  box-shadow: 0 0 0 2px #00c2a3;
  background: white;
  background-color: white;
}

.ant-input-lg {
  padding: 15px 20px;
  font-size: 17px;
}

.ant-btn {
  height: 45px;
}

.ant-btn-primary, .ant-btn-primary:active, .ant-btn-primary:focus, .ant-btn-primary:hover {
  background: #8847E6;
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
  background: #CBCBCB;
  color: white;
}

/* ANIMATIONS */
.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.ant-skeleton-content .ant-skeleton-paragraph {
  margin-bottom: 0;
}

.ant-skeleton.ant-skeleton-block .ant-skeleton-button {
  border-radius: 8px;
  height: 40px;
  min-width: 110px;
  max-width: 144px;
}

.ant-skeleton-content .ant-skeleton-paragraph > li {
  border-radius: 20px;
  height: 10px;
}

.ant-progress {
  margin-top: 15px;
}

.ant-progress-inner {
  padding: 8px;
  background-color: #F7FAFC;
}

.ant-select-selector {
  border-radius: 8px;
  height: 40px;
  padding: 5px 16px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px;
  height: 40px;
  padding: 5px 16px;
}

.ant-select.errorSelect .ant-select-selector {
  border-color: #d32f2f;
}
