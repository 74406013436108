/* FONTS */
@font-face {
  font-family: 'RedHatDisplay-Bold';
  src: url('./assets/fonts/RedHatDisplay-Bold.ttf');
}

@font-face {
  font-family: 'RedHatDisplay-Medium';
  src: url('./assets/fonts/RedHatDisplay-Medium.ttf');
}

@font-face {
  font-family: 'RedHatDisplay-Light';
  src: url('./assets/fonts/RedHatDisplay-Light.ttf');
}

@font-face {
  font-family: 'RedHatDisplay-Regular';
  src: url('./assets/fonts/RedHatDisplay-Regular.ttf');
}

@font-face {
  font-family: 'RedHatDisplay-Semibold';
  src: url('./assets/fonts/RedHatDisplay-SemiBold.ttf');
}

html {
  scroll-behavior: smooth;
}

* {
  margin: 0;
  font-family: 'RedHatDisplay-Medium';
  font-feature-settings: normal;
  -moz-font-feature-settings: normal;
  -webkit-font-feature-settings: normal;
  font-variant: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h3 {
  font-family: 'RedHatDisplay-Regular';
  font-weight: 600;
}
