.meetingContactSoonCard {
  border-width: 1px;
  border-color: #D6D6D6;
  border-style: solid;
  border-radius: 8px;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 56px;
}

@media (max-width: 768px) {
  .meetingContactSoonCard {
    padding: 48px 24px;
  }
}
