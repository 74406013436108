.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: inherit;
}

.buttonWrapper {
    display: flex;
    align-items: center;
    z-index: 2;
    background: inherit;
}

.button {
    border: none;
    text-decoration: none;
    cursor: pointer;
    border-radius: 5px;
    width: 25px;
    height: 25px;
    color: white;
    font-size: 20px;
    font-weight: bold;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}

.button:first-of-type {
    margin-right: 10px;
}

.button:last-of-type {
    margin-left: 10px;
}

.dateListScrollable {
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.dateListScrollable::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
}

.monthContainer {
    display: flex;
    flex-direction: column;
    border-left: 1px solid lightgray;
    margin-left: 5px;
}

.monthContainer:first-of-type {
    border-left: none;
    margin-left: 0;
}

.monthYearLabel {
    align-self: flex-start;
    z-index: 3;
    font-size: 15px;
    font-weight: bold;
    position: sticky;
    top: 0;
    left: 0;
    width: max-content;
    margin: 0 14px 10px 7px;
    color: #D6D6D6;
}

.dateDayItem{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 0 0 5px;
    border-radius: 10px;
    background-color: #F6F3EC;
    border: 1px solid #F6F3EC;
    width: 46px;
    height: 60px;
    flex-shrink: 0;
    transition: all .3s ease-in-out;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
}

.dateDaySelected {
    color: black;
    background-color: white;
    border: 2px solid #A5D663;
    box-shadow: 0px 3px 20px 6px rgba(50, 50, 71, 0.1);
}

.daysContainer {
    display: flex;
    z-index: 1;
    padding-bottom: 5px;
}

.dayLabel {
    font-size: 12px;
    margin: 4px 0 0 0;
}

.dateLabel {
    font-size: 18px;
}
  
.monthHeader {
    text-align: center;
    margin-bottom: 10px;
}

.monthLabel {
    text-align: center;
    margin-bottom: 5px;
}

.monthSeparator {
    font-size: 2.5em;
    text-align: center;
    margin-left: 8px;
    margin-right: 2px;
}
