.meetingConfirmationContainer {
  height: 100%;
  padding: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 768px) {
  .meetingConfirmationContainer {
    padding: 0;
  }
}
