.meetingContainer {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

.skeletonWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
}

.skeletonTextContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.meetingSkeletonParagraph {
  width: 140px;
}

@media (max-width: 768px) {
  .meetingSkeletonParagraph {
    width: 102px;
  }
}
