.containerRadioFields {
    display: flex;
    flex-direction: row; 
    flex-wrap: wrap;
    gap: 15px;
}

.selectStyle {
  display: flex;
  align-items: center;
  border-radius: 8px;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4;
  height: 50px;
  padding: 10px 16px;
  min-width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: #EBEBEB;
  cursor: pointer;
  user-select: none;
  transition: all .3s ease-out;
  flex: 1 1 33%;
  background-color: #FFFFFF;
  border: 1px solid #CBD5E0;
}

.selectStyle:hover {
    border-color: #A5D663;
}

.selectedItem {
  border-color: transparent !important;
  box-shadow: 0 0 0 2px #A5D663;
  background-color: white;
}

.partnerSelectedItem {
  border-color: transparent !important;
  box-shadow: 0 0 0 2px #00c2a3;
  background-color: white;
}

.titleStyle {
  line-height: 1.4;
  font-weight: 600;
  font-size: 20px;
  color: #8847E6;
}

.partnerTitleStyle {
  line-height: 1.4;
  font-weight: 600;
  font-size: 20px;
  color: black;
}

.subtitleStyle {
    margin-bottom: 30px;
    color: #718096;
    font-size: 11px;
}