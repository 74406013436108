.platformNotAvailableContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 9;
  color: white;
  font-family: 'RedHatDisplay-Semibold';
  font-size: 18px;
}

.formMainCard {
  max-width: 575px;
  min-width: 375px;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  padding: 60px;
}

@media (max-width: 768px) {
  .formMainCard {
    margin-top: 0;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 40px;
  }
}
