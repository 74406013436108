@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loadingSpinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3;
    border-top: 10px solid #A5D663;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
}

.spinnerContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}